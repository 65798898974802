<template>
    <v-row justify="center">
        <v-col cols="12" sm="10" md="8">
            <v-row v-if="section == 1" class="mt-2">
                <v-col cols="12">
                    <project-info-card
                        v-if="project.id"
                        :project="project"
                        type="project"
                        card-width="100%"
                        :card-heigth="cardHeight"
                        :media-height="mediaHeight"
                        media-width="540"
                    ></project-info-card>
                    <div v-if="project.description" class="mt-10">
                        <description-alert
                            color="secondary darken-2"
                            icon="fa-solid fa-seedling"
                        >
                            <template #description>
                                {{ project.description }}
                            </template>
                        </description-alert>
                    </div>
                </v-col>
                <v-col cols="12" class="d-md-flex justify-md-space-between">
                    <requirements
                        v-if="hasIngredients"
                        :title="$t(`project.requirements`)"
                        :items="project.ingredients"
                    ></requirements>
                    <view-steps-btn
                        v-if="hasSteps"
                        @view-steps="viewSteps()"
                    ></view-steps-btn>
                </v-col>
            </v-row>
            <v-row v-else class="mt-2">
                <v-col cols="12">
                    <v-btn
                        text
                        color="text lighten-1"
                        link
                        @click="returnBack()"
                    >
                        <v-icon class="mr-2">
                            fa-solid fa-arrow-left
                        </v-icon>
                        {{ $t(`MyProjects.myProjectInfo.back`) }}
                    </v-btn>
                </v-col>
                <v-col v-if="hasSteps" cols="12">
                    <view-stepper :project-steps="project.steps"></view-stepper>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { withAsync } from "@/helpers/withAsync.js";
import { getProject } from "@/api/projectsApi.js";
import { CONFIG } from "@/common/config.js";

import ProjectInfoCard from "@/components/InfoCard/ProjectInfoCard.vue";
import Requirements from "@/components/General/Requirements.vue";
import DescriptionAlert from "@/components/General/DescriptionAlert.vue";
import ViewStepper from "@/components/General/ViewStepper.vue";
export default {
    name: "GardenInfo",

    components: {
        ProjectInfoCard,
        Requirements,
        DescriptionAlert,
        ViewStepsBtn: () => import(`@/components/General/ViewStepsBtn.vue`),
        ViewStepper,
    },

    data() {
        return {
            section: 1,
            project: {},
            cardHeight: CONFIG.card_heigth,
            mediaHeight: CONFIG.media_height,
        };
    },

    computed: {
        hasSteps() {
            return this.project?.steps?.length > 0 ? true : false;
        },
        hasIngredients() {
            return this.project.ingredients ? true : false;
        },
    },

    methods: {
        async handleProject() {
            const payload = {
                id: this.$route.params.id,
            };
            const { response, error } = await withAsync(getProject, payload);

            if (error) {
                return;
            }

            this.project = response.data.data;
        },
        viewSteps() {
            this.section = 2;
        },

        returnBack() {
            this.section = 1;
        },
    },

    created() {
        this.handleProject();
    },
};
</script>

<style lang="scss" scoped></style>
